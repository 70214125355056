// ============================================================================
// ProjectsManagerService
// ----------------------
// ProjectsManager module related services
// ============================================================================

// -------
// Imports
// -------
import { router } from '@/router'
import { store } from '@/store'

import API from '@/apis/ProjectsManagerApi'
import { Bus as BUS, Events as EVENTS } from '@/events/Offers/ProjectsManagerEvents'

import AppService from '@/services/AppService'
import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

import CatalogService from '@/classes/Offers/CatalogService'
import Category from '@/classes/Offers/Category'
import Frequency from '@/classes/Offers/Frequency'
import Service from '@/classes/Offers/Service'
import Status from '@/classes/Offers/Status'
import Project from '@/classes/Offers/Project'

// ---------
// Internals
// ---------
const Private = {
	updateProject: function (projectId, data) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id

		if (typeof data?.users !== 'undefined') {
			data.users = !Array.isArray(data?.users) ? [] : data?.users
			data.users = Object.values(data.users).map(a => (typeof a?.id !== 'undefined' ? a.id : a))
			data.users.filter(n => n !== null)
		}

		if (data.is_renewable != null) {
			data = {...data, is_renewable: data.is_renewable}
		}

		return API.updateProject(accountingFirmId, vendorId, projectId, data)
			.then(res => res.data.data)
			.then(project => {
				project = new Project(project)
				BUS.emit(EVENTS.PROJECT_UPDATED, project)
			})
	}
}

// -------
// Exports
// -------
export default {
	listStatuses: function () {
		store.dispatch('customers/projects/setLoader', {
			key: 'listStatuses',
			value: true
		})
		return API.listStatuses()
			.then(res => res.data.data)
			.then(statuses => statuses.map(status => new Status(status)))
			.then(statuses => {
				store.dispatch('customers/projects/setStatuses', statuses)
				const defaultStatuses = store.state.customers.projects.statuses.filter(status => ['not-signed', 'signed'].includes(status.key)).map(status => status.id)
				store.dispatch('customers/projects/setSearchedStatuses', defaultStatuses)
			})
			.finally(() => {
				store.dispatch('customers/projects/setLoader', {
					key: 'listStatuses',
					value: false
				})
			})
	},
	listCategories: function (relationships = [], pagination) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const params = {
			only_show_full_categories: true,
			with: relationships
		}
		if (pagination) {
			params.page = pagination.page
			params.po = pagination.rowsPerPage
		}
		return API.listCategories(accountingFirmId, params)
			.then(res => res.data)
			.then(({ data: categories, pagination: paginationData }) => {
				categories = categories.map(c => new Category(c))
				if (pagination) {
					return {
						categories,
						paginationData
					}
				}
				return categories
			})
	},
	listFrequencies: function () {
		return API.listFrequencies({ with: ['endings'] })
			.then(res => res.data.data)
			.then(frequencies => frequencies.map(freq => new Frequency(freq)))
	},
	listAccountingFirmUsers: function (projectId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.listAccountingFirmUsers(accountingFirmId, vendorId, projectId).then(res => res.data.data)
	},
	createProject: function (data, config = {}) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.createProject(accountingFirmId, vendorId, data)
			.then(res => res.data.data)
			.then(project => {
				if (config.show_success !== false) {
					//AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('projects.project_created'))
				}
				BUS.emit(EVENTS.PROJECT_CREATED, project)
				return new Project(project)
			})
	},
	deleteProject: function (accountingFirmId, vendorId, projectId) {
		return API.deleteProject(accountingFirmId, vendorId, projectId).then(() => {
			BUS.emit(EVENTS.PROJECT_DELETED, projectId)
		})
	},
	findProject: function (projectId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.findProject(accountingFirmId, vendorId, projectId)
			.then(res => res.data.data)
			.then(project => new Project(project))
	},
	findServiceInterval: function (data, config, cancelToken = null) {
		return API.findServiceInterval(data, config, cancelToken)
	},
	listServiceTasks: function (data, config, cancelToken = null) {
		return API.listServiceTasks(data, config, cancelToken)
	},
	listProjects: function (
		accountingFirmId,
		vendorId,
		filters = {
			categories: [],
			onlyCurrentProjects: false,
			search: '',
			statuses: []
		},
		pagination = {
			page: 1,
			rowsPerPage: 15,
			sortBy: [],
			sortDesc: []
		},
		cancelToken
	) {
		let sort = []
		if (Array.isArray(pagination.sortBy) && pagination.sortBy.length > 0) {
			pagination.sortBy.forEach((item, index) => {
				let desc = false
				if (pagination.sortDesc[index]) {
					desc = true
				}

				sort.push(`${desc ? '-' : '+'}${item}`)
			})
		}
		const params = {
			accounting_firm_id: accountingFirmId,
			categories: filters.categories,
			company_id: vendorId,
			current_projects: filters.onlyCurrentProjects,
			page: pagination.page,
			po: pagination.rowsPerPage,
			q: filters.search,
			sort: sort.join(','),
			statuses: filters.statuses
		}
		const callAnswer = API.listProjects(params, cancelToken)
		return {
			...callAnswer,
			promise: callAnswer.promise
				.then(res => res.data)
				.then(data => {
					return {
						paginationData: {
							...data.pagination,
							totalItems: data.pagination.total
						},
						projects: data.data.map(p => new Project(p))
					}
				})
		}
	},
	renameProject: function (projectId, projectName) {
		return Private.updateProject(projectId, { name: projectName })
	},
	updateProject: Private.updateProject,
	updateService: API.updateService,
	deleteService: function (service) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.deleteService(accountingFirmId, vendorId, service.id).then(() => {
			BUS.emit(EVENTS.SERVICE_DELETED, { id: service.id, project_id: service.project_id })
		})
	},
	deleteAllServicesIntervals: function (projectId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.deleteAllServicesIntervals(accountingFirmId, vendorId, projectId)
	},
	deleteAllAssociatedTasks: function (projectId) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.deleteAllAssociatedTasks(accountingFirmId, vendorId, projectId)
	},
	createService: function (service) {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		const vendorId = store.state.company?.selected?.id
		return API.createService({
			...service.forApi(),
			accounting_firm_id: accountingFirmId,
			vendor_id: vendorId
		})
			.then(res => res.data.data)
			.then(createdService => {
				createdService = new Service(createdService)
				BUS.emit(EVENTS.SERVICE_CREATED, createdService)
				AppEventBus.emit(
					AppEvents.SNACKBAR_SUCCESS,
					window.vueInstance.$t('projects.service_added', {
						project: createdService.project.name,
						service: createdService.name
					})
				)
				return createdService
			})
	},
	listServices: function (accountingFirmId, vendorId, filters = {}, paginationData = {}, cancelToken = null) {
		const params = {}
		if (filters) {
			if (Array.isArray(filters.categories)) {
				params.categories = filters.categories
			}
			params.current_projects = filters.onlyCurrentProjects ?? false
			params.search = filters.search
			if (Array.isArray(filters.statuses)) {
				params.statuses = filters.statuses
			}
		}
		if (paginationData) {
			params.page = paginationData.page
			params.po = paginationData.rowsPerPage
			params.s = paginationData.sortBy
			params.s_d = paginationData.descending ? 'DESC' : 'ASC'
		}
		const callAnswer = API.listServices(accountingFirmId, vendorId, params, cancelToken)
		return {
			...callAnswer,
			promise: callAnswer.promise
				.then(res => res.data)
				.then(({ data: services, pagination: updatedPaginationData }) => {
					return {
						paginationData: updatedPaginationData,
						services: services.map(s => new Service(s))
					}
				})
		}
	},
	listCatalogServices: function (accountingFirmId, paginationData, cancelToken) {
		const result = API.listCatalogServices(
			accountingFirmId,
			{
				page: paginationData.page,
				po: paginationData.rowsPerPage
			},
			cancelToken
		)
		return {
			...result,
			promise: result.promise
				.then(res => res.data)
				.then(({ data: catalogServices, pagination: updatedPaginationData }) => {
					return {
						catalogServices: catalogServices.map(cs => new CatalogService(cs)),
						paginationData: updatedPaginationData
					}
				})
		}
	},
	selectProject: function (projectId) {
		const currentRoute = router.history.current
		AppService.goTo({
			name: 'customer-services-project',
			params: Object.assign(currentRoute.params, {
				projectId: projectId.toString(16),
				serviceId: undefined
			})
		})
	},
	selectService: function (projectId, serviceId) {
		const currentRoute = router.history.current
		AppService.goTo({
			name: 'customer-services-project',
			params: Object.assign(currentRoute.params, {
				projectId: projectId.toString(16),
				serviceId: serviceId.toString(16)
			})
		})
	}
}
