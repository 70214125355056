<template>
	<w-layout v-if="contextLoadings === 0" column fill-height>
		<template v-if="hasCatalog && hasServices">
			<w-flex shrink>
				<ProjectsContainerHeader />
			</w-flex>
			<w-flex scroll-y>
				<ProjectsList :selected-project-id="projectId" :selected-service-id="serviceId" />
			</w-flex>
		</template>
		<UndefinedCatalog v-else-if="!hasCatalog" />
		<NoExistingProjects v-else />
		<w-navigation-drawer v-if="showNavigationDrawer" :value="showNavigationDrawer" absolute clipped default-width="33%" min-width="550px" resizable right>
			<template v-slot:default="{ on }">
				<w-layout column fill-height>
					<router-view v-on="on" />
				</w-layout>
			</template>
		</w-navigation-drawer>
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ProjectsContainer',
	components: {
		NoExistingProjects: () => ({
			component: import('@/components/Offers/CustomersManager/NoExistingProjects')
		}),
		ProjectsContainerHeader: () => ({
			component: import('@/components/Offers/CustomersManager/ProjectsContainerHeader')
		}),
		ProjectsList: () => ({
			component: import('@/components/Offers/CustomersManager/ProjectsList')
		}),
		UndefinedCatalog: () => ({
			component: import('@/components/Offers/CustomersManager/UndefinedCatalog')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		projectId: {
			default: undefined,
			required: false,
			type: Number
		},
		serviceId: {
			default: undefined,
			required: false,
			type: Number
		}
	},
	data: function () {
		return {
			cancelToken: null,
			contextLoadings: 0,
			navDrawerDefaultWidth: undefined,
			hasCatalog: false,
			hasServices: false
		}
	},
	computed: {
		showNavigationDrawer: function () {
			return this.$route.name.length > 'customer-services'.length
		}
	},
	watch: {
		accountingFirmId: {
			handler: 'onAccountingFirmIdUpdated',
			immediate: true
		},
		vendorId: {
			handler: 'onCompanyIdUpdated',
			immediate: true
		}
	},
	created: function () {
		this.service.listStatuses()
	},
	destroyed: function () {
		this.$store.dispatch('customers/projects/reset')
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.PROJECT_CREATED, action: this.onProjectCreated },
				{ event: this.events.SERVICE_CREATED, action: this.onServiceCreated }
			]
		},
		onAccountingFirmIdUpdated: async function (newVal) {
			this.contextLoadings++
			await this.$store.dispatch('customers/projects/resetFilters')
			await this.$store.dispatch('customers/projects/setCategories', [])
			await this.$store.dispatch('customers/projects/setSearchedCategories', [])
			if (newVal) {
				const { paginationData } = await this.service.listCategories([], {
					page: 1,
					rowsPerPage: 1
				})
				if (paginationData.total === 0) {
					this.hasCatalog = false
					this.contextLoadings--
					return
				}
				this.hasCatalog = true
				this.$store.dispatch('customers/projects/setLoader', {
					key: 'listCategories',
					value: true
				})
				const categories = await this.service.listCategories()
				await this.$store.dispatch('customers/projects/setCategories', categories)
				this.$store.dispatch('customers/projects/setLoader', {
					key: 'listCategories',
					value: false
				})
			} else {
				this.$store.dispatch('customers/projects/reset')
			}
			this.contextLoadings--
		},
		onCompanyIdUpdated: async function (vendorId) {
			this.contextLoadings++
			await this.$store.dispatch('customers/projects/resetFilters')
			const callAnswer = this.service.listServices(this.accountingFirmId, vendorId, undefined, { page: 1, rowsPerPage: 1 }, this.cancelToken)
			this.cancelToken = callAnswer.cancelToken
			const { paginationData } = await callAnswer.promise
			if (paginationData.total === 0) {
				this.hasServices = false
			} else {
				this.hasServices = true
			}
			this.contextLoadings--
		},
		onServiceCreated: function () {
			this.hasServices = true
		},
		onProjectCreated: function () {
			this.hasServices = true
		}
	}
}
</script>
