import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	PROJECT_CREATED: 'project-created',
	PROJECT_DELETED: 'project-deleted',
	PROJECT_COMPLETED: 'project-completed',
	PROJECT_UPDATED: 'project-updated',
	SERVICE_CREATED: 'service-created',
	SERVICE_DELETED: 'service-deleted',
	SERVICE_UPDATED: 'service-updated'
})
