import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/Offers/ProjectsManagerService'
import { Events, Bus } from '@/events/Offers/ProjectsManagerEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'project-management',
			eventBus: Bus,
			service: Service,
			events: Events
		}
	}
}
