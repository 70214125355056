import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const CancelTokenMessage = 'Request cancelled'

const listCategories = (accountingFirmId, params) => {
	const url = `accounting-firms/${accountingFirmId}/categories`
	return axios.get(url, { params })
}

const listFrequencies = data => {
	const query = {}
	if (data.with) {
		query.with = data.with
	}

	const url = 'frequencies'
	return axios.get(url, {
		params: query
	})
}

const listAccountingFirmUsers = (accountingFirmId, vendorId, projectId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}/users`
	return axios.get(url)
}

const createProject = (accountingFirmId, vendorId, data) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects`
	return axios.post(url, data)
}

const deleteProject = (accountingFirmId, vendorId, projectId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}`
	return axios.delete(url)
}

const findProject = (accountingFirmId, vendorId, projectId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}`
	return axios.get(url)
}

const deleteProjectTasks = params => {
	const url = `accounting-firms/${params.accounting_firm_id}/companies/${params.company_id}/projects/${params.project_id}/tasks`

	return axios.delete(url)
}

const listProjects = (data, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${data.accounting_firm_id}/companies/${data.company_id}/projects`
	delete data.accounting_firm_id
	delete data.company_id

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: data
		})
	}
}

const updateProject = (accountingFirmId, vendorId, projectId, data) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}`
	return axios.patch(url, data)
}

const listProjectTasks = (params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${params.accounting_firm_id}/companies/${params.company_id}/projects/${params.project_id}/tasks`

	const query = {
		page: params.page,
		po: params.po
	}

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: query
		})
	}
}

const listServiceTasks = (params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${params.accounting_firm_id}/companies/${params.company_id}/services/${params.service_id}/tasks`

	const query = {
		only_soon_to_ending_or_unfinished: params.only_soon_to_ending_or_unfinished,
		page: params.page,
		po: params.po
	}

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: query
		})
	}
}

const createService = data => {
	let query = {}

	if (data.with) {
		query.with = data.with
		delete data.with
	}

	const accountingFirmId = data.accounting_firm_id
	const vendorId = data.vendor_id
	delete data.accounting_firm_id
	delete data.vendor_id

	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/services`
	return axios.post(url, data, { params: query })
}

const deleteService = (accountingFirmId, vendorId, serviceId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/services/${serviceId}`
	return axios.delete(url)
}

const deleteAllServicesIntervals = (accountingFirmId, vendorId, projectId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}/intervals`
	return axios.delete(url)
}

const deleteAllAssociatedTasks = (accountingFirmId, vendorId, projectId) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/projects/${projectId}/tasks`
	return axios.delete(url)
}

const listServices = (accountingFirmId, vendorId, data, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/services`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: data
		})
	}
}

const listCatalogServices = (accountingFirmId, params, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}

	const newCancelToken = Configurator.CancelToken.source()
	const url = `accounting-firms/${accountingFirmId}/catalog-services`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			params
		})
	}
}

const listCatalogServicesOfCategory = (accountingFirmId, categoryId, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}/catalog-services`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token
		})
	}
}

const updateService = (accountingFirmId, vendorId, serviceId, data) => {
	const url = `accounting-firms/${accountingFirmId}/companies/${vendorId}/services/${serviceId}`
	return axios.patch(url, data)
}

const findServiceInterval = (data, config, cancelToken = null) => {
	if (cancelToken) {
		cancelToken.cancel(CancelTokenMessage)
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${data.accounting_firm_id}/companies/${data.company_id}/services/${data.service_id}/intervals/${data.service_interval_id}`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, config)
	}
}

const listStatuses = () => {
	const url = 'project-statuses'
	return axios.get(url)
}

export default {
	listCategories,

	listFrequencies,

	listAccountingFirmUsers,
	createProject,
	deleteProject,
	findProject,
	listProjects,
	updateProject,

	deleteProjectTasks,
	listProjectTasks,
	listServiceTasks,

	createService,
	deleteService,
	listServices,
	listCatalogServices,
	listCatalogServicesOfCategory,
	updateService,
	deleteAllServicesIntervals,
	deleteAllAssociatedTasks,

	findServiceInterval,

	listStatuses
}
